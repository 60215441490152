










































































































































































import Vue from "vue";
import HelloUser from "@/components/shared/HelloUser.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  APP_ASSESSMENTS,
  APP_ASSESSMENT_RESULT
} from "@/store/modules/common/constants";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { AppAssessmentNames } from "@/store/modules/common/interfaces";
import { ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import { GET_COMPANY_DETAILS } from "@/store/modules/auth/constants";
import {
  GET_USER_DETAILS,
  UPDATE_USER_DETAILS
} from "@/store/modules/auth/constants";
import { REDO_ASSESSMENT_REQUEST } from "@/store/modules/recruiter/constants";
import { redo_assessment_request } from "@/store/apis/recruiter";
import { CHECK_SURVEY_ALREADY_SUBMITTED } from "@/store/modules/candidates/constants";

export default Vue.extend({
  name: "InceptionCandidateDashboard",
  components: {
    HelloUser
  },
  data() {
    return {
      loading: true,
      is_show_english_assessment: true,
      is_show_annotation_assessment: true,
      english_assessment_status: null as string | null,
      annotation_assessment_status: null as string | null,
      dialog: {
        value: false
      },
      eng_version: "",
      ann_version: "",
      survey_popup: false,
      survey_already_submitted: false,
      is_both_assessment_completed: false
    };
  },
  created() {
    // this.show_intro();
  },
  async mounted() {
    // Find English Assessment
    this.eng_version =
      this.get_company_details.s3_path.assessment_setting.candidates_english;
    this.ann_version =
      this.get_company_details.s3_path.assessment_setting.candidates_annotation;

    await this.find_assessment(this.eng_version, this.ann_version);
    this.survey_already_submitted = await this.check_survey_submitted();
    if (this.survey_already_submitted) {
      this.survey_popup = false;
    } else {
      this.survey_popup = this.is_both_assessment_completed;
    }
    localStorage.removeItem("stage");
    localStorage.removeItem("partTime");
    localStorage.removeItem("partsTime");
    localStorage.removeItem("questionTime");
    localStorage.removeItem("englishAssessmentTimer");
    localStorage.removeItem("question_id");
    localStorage.removeItem("answers");
    localStorage.removeItem("assessmentTime");
    localStorage.removeItem("assessment_cancelled");
    localStorage.removeItem("eng-terms-accepted");
  },
  // async beforeRouteLeave(to, from, next) {
  // const thisself = this;
  // console.log("f2 leave");
  // Convert `this.response` to a string before storing it
  // localStorage.setItem("leaving_stage", this.response);
  // console.log("Saved response to localStorage");
  // next();
  // },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS
    }),
    ...mapGetters("auth", {
      get_company_details: GET_COMPANY_DETAILS
    })
  },
  methods: {
    ...mapActions("common", {
      app_assessment_result: APP_ASSESSMENT_RESULT
    }),
    ...mapActions("auth", {
      update_user_details: UPDATE_USER_DETAILS
    }),
    ...mapActions("recruiter", {
      redo_assessment_request: REDO_ASSESSMENT_REQUEST
    }),
    ...mapMutations({
      set_root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("candidate", {
      check_survey_submitted: CHECK_SURVEY_ALREADY_SUBMITTED
    }),
    resume_assessment(data: string) {
      console.log(
        data,
        "english_assessment_status: ",
        this.english_assessment_status
      );
    },
    async find_assessment(english_version: string, annotation_version: string) {
      console.log("Finding");
      let englishAssessmentExist = {} as AppAssessments.AssessmentResults;
      let annotationAssessmentExist = {} as AppAssessments.AssessmentResults;
      console.log(annotationAssessmentExist, "start annotationAssessmentExist");
      const get_assessments = async (
        english_version: string,
        annotation_version: string
      ) => {
        this.loading = true;

        if (englishAssessmentExist !== null) {
          const english_assessment = this.app_assessments.find(
            (val: AppAssessments.Assessment) =>
              val.assessment_type === english_version
          );
          // Check if english assessment is attempted or not

          if (english_assessment) {
            englishAssessmentExist = await this.app_assessment_result({
              assessment_id: english_assessment.id
            });
            if (englishAssessmentExist) {
              this.english_assessment_status = englishAssessmentExist.status;
              if (
                englishAssessmentExist.status ===
                AppAssessments.AppAssessmentStatus.CANCELLED
              ) {
                this.is_show_english_assessment = true;
              } else {
                this.is_show_english_assessment = false;
              }
              this.is_both_assessment_completed = true;
            }
          }
        }

        // Find Annotation Assessment
        if (!annotationAssessmentExist) return;
        const annotation_assessment = this.app_assessments.find(
          (val: AppAssessments.Assessment) =>
            val.assessment_type === annotation_version
        );
        // Check if annotation assessment is attempted or not
        if (annotation_assessment) {
          annotationAssessmentExist = await this.app_assessment_result({
            assessment_id: annotation_assessment.id
          });
          if (annotationAssessmentExist) {
            this.annotation_assessment_status =
              annotationAssessmentExist.status;
            if (
              annotationAssessmentExist.status ===
              AppAssessments.AppAssessmentStatus.CANCELLED
            ) {
              this.is_show_annotation_assessment = true;
            } else {
              this.is_show_annotation_assessment = false;
            }
          }
        }
        if (
          annotation_assessment &&
          annotationAssessmentExist &&
          this.is_both_assessment_completed
        ) {
          this.is_both_assessment_completed = true;
        } else {
          this.is_both_assessment_completed = false;
        }

        this.loading = false;
      };

      await get_assessments(english_version, annotation_version);
      console.log(annotationAssessmentExist, "annotationAssessmentExist");
      // Check if english and annotation assessment version exist or not
      if (!englishAssessmentExist) {
        switch (english_version) {
          case AppAssessmentNames.english_v1:
            english_version = AppAssessmentNames.english_v1;
            break;
          case AppAssessmentNames.english_v2:
            english_version = AppAssessmentNames.english_v2;
            break;
          case AppAssessmentNames.english_v3:
            english_version = AppAssessmentNames.english_v3;
            break;
          default:
            english_version = AppAssessmentNames.english_v1; // Fallback to the first version
        }
      }
      if (!annotationAssessmentExist) {
        switch (annotation_version) {
          case AppAssessmentNames.annotation_v1:
            annotation_version = AppAssessmentNames.annotation_v2;
            break;
          case AppAssessmentNames.annotation_v2:
            annotation_version = AppAssessmentNames.annotation_v3;
            break;
          case AppAssessmentNames.annotation_v3:
            annotation_version = AppAssessmentNames.annotation_v3;
            break;
          default:
            annotation_version = AppAssessmentNames.annotation_v1; // Fallback to the first version
        }
      }
      if (!englishAssessmentExist || !annotationAssessmentExist) {
        englishAssessmentExist = {} as AppAssessments.AssessmentResults;
        annotationAssessmentExist = {} as AppAssessments.AssessmentResults;
        await get_assessments(english_version, annotation_version);
      }
    },
    async navigateToIntro() {
      this.dialog.value = false;
      // User Data
      let user_data = {
        ...this.get_user_details.user_data,
        is_intro_showed: true
      };

      // const response =
      await this.update_user_details({ user_data });
      // if (response) {
      //   this.set_root_notification("Data Upload Successfully");
      // }

      this.$router.push({ name: "dashboard-intro" });
    },
    show_intro() {
      if (
        this.get_user_details.user_data &&
        this.get_user_details.user_data.is_intro_showed
      ) {
        this.dialog.value = false;
      } else this.dialog.value = true;
    },
    async do_assessment(assessment_type: string) {
      this.loading = true;
      if (assessment_type === "english") {
        if (this.english_assessment_status === "CANCELLED") {
          const english_assessment = this.app_assessments.find(
            (val: AppAssessments.Assessment) =>
              val.assessment_type === this.eng_version
          );
          await redo_assessment_request(english_assessment.id);
          this.english_assessment_status = "REDO";
          this.is_show_english_assessment = false;
        } else {
          this.$router.push("/candidate/assessments/english");
        }
      } else {
        if (this.annotation_assessment_status === "CANCELLED") {
          const annotation_assessment = this.app_assessments.find(
            (val: AppAssessments.Assessment) =>
              val.assessment_type === this.ann_version
          );
          await redo_assessment_request(annotation_assessment.id);
          this.annotation_assessment_status = "REDO";
          this.is_show_annotation_assessment = false;
        } else {
          this.$router.push("/candidate/assessments/annotation");
        }
      }
      this.loading = false;
    }
  }
});
